<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background:new 0 0 512.001 512.001;"
    xml:space="preserve"
  >
    <path
      style="fill:#F64B4A;"
      d="M463.296,201.393c-5.215-268.523-409.376-268.523-414.591,0c-0.042,2.151-0.063,4.307-0.063,6.468
	c0,138.295,85.958,256.508,207.358,304.14c121.4-47.633,207.359-165.845,207.359-304.14
	C463.359,205.7,463.338,203.543,463.296,201.393z"
    />
    <circle style="fill:#FED159;" cx="255.994" cy="208.184" r="149.829" />
    <path
      style="fill:#FFE4A9;"
      d="M256,58.349c-3.612,0-7.194,0.135-10.742,0.388c77.737,5.508,139.092,70.308,139.092,149.448
	s-61.355,143.939-139.092,149.448c3.548,0.251,7.13,0.388,10.742,0.388c82.752,0,149.834-67.083,149.834-149.834
	S338.751,58.349,256,58.349z"
    />
    <path
      style="fill:#F6C454;"
      d="M133.233,208.183c0-78.155,59.843-142.324,136.206-149.211c-4.429-0.396-8.906-0.624-13.438-0.624
	c-82.752,0-149.834,67.084-149.834,149.834s67.084,149.834,149.834,149.834c4.531,0,9.008-0.228,13.438-0.624
	C193.076,350.507,133.233,286.338,133.233,208.183z"
    />
    <polygon
      style="fill:#EAB64E;"
      points="314.042,169.509 314.042,144.902 268.303,144.902 268.303,113.205 243.696,113.205 
	243.696,144.902 197.958,144.902 197.958,224.98 289.435,224.98 289.435,255.844 197.958,255.844 197.958,280.451 243.696,280.451 
	243.696,312.148 268.303,312.148 268.303,280.451 314.042,280.451 314.042,200.374 222.564,200.374 222.564,169.509 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
